import { ArticleType, DocumentType } from '@innedit/innedit-type';
import { ArticleData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Media from '~/components/Media';
import { ListItemProps } from '~/containers/Espace/List/props';

import IconEye from '../../../icons/Eye';
import IconEyeSlash from '../../../icons/EyeSlash';
import ListItem from './index';

const ListItemArticle: FC<ListItemProps<ArticleType, ArticleData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<ArticleType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  let media;

  if (doc.medias && doc.medias.length > 0) {
    [media] = doc.medias.filter(m =>
      ['video/', 'image/'].includes(m.type?.slice(0, 6)),
    );
  }

  return (
    <ListItem
      actions={[
        {
          icon: doc.hidden ? IconEyeSlash : IconEye,
          label: 'Changer la visibilité',
        },
      ]}
      displayActionId={false}
      doc={doc}
      icon={
        media && (
          <Media
            media={media}
            options={{
              crop: 'contain',
              height: 42,
              width: 42,
            }}
            thumbnail
          />
        )
      }
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemArticle;
